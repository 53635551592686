.align-items-center {
    align-items: center!important;
}

.d-flex {
    display: flex!important;
}

.wrapper {
    display: 'flex'
}

.main{ 
  
    height: 100vh;
    overflow: auto;
}


.bg-login{
    background-color: #1751a1;
    background-position: center;
    background-size:cover;
}

.h-100{
    height: 100%!important;
  }



.login{
    text-align:left;
    background-color:white;
}  


