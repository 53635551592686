.tabla-servicios{
    td{
        &.fecha_hora{
            width: 12%;
        }

        &.acciones{
            width: 7%;
        }
    }
    th{
       background-color: rgb(64 117 142 / 12%) !important;
    }
}