.ui.selection.dropdown{
    //background-color: #d4d4d554 !important;
    border-color: rgb(64, 117, 142) !important;
    border-radius: 10px !important;
    &:focus{
        background-color: #fff !important;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2) !important;
        -webkit-box-shadowbox-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2) !important;
        -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2) !important;
    }
}