

/* 
& es el nombre de la clase padre
&__title = app__title
&_a = app__title_a

*/
.app {
    background: green;
    &__title{
        color:white;
        &_a{
            color:aqua;
        }
    }
}
    