.segment_border_primary{
    border-top: 4px solid;
    border-color: rgb(64, 117, 142);
}
.toast-loading {
    position: fixed !important;
    z-index: 9999!important;
    top: 0!important;
    left: 0!important;
    width: 100%!important;
    height: 100%!important;
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
    background-color: rgba(0, 0, 0, 0.5)!important;
  }

.titulo_comentarios{
    margin-left: 12px;
}
  
.textarea_comentarios{
    display: flex;
}