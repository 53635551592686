.h1-login-form{
    color: #1751a1;
    font-size: 24px;
    font-family: Montserrat, serif;
    font-weight: 400;
    line-height: 1.235;
    letter-spacing: -1px;
}

.h2-login-form{
    color: #1751a1;
    font-size: 16px;
    font-family: Montserrat, serif;
    font-weight: 500;
    line-height: 1.334;
    letter-spacing: -0.5px;
}

.login-admin{
    padding: 32px;
}