.checkbox{
    padding-top: 12px;
}
.segment_size{
    width: calc(100% - 500px) !important;
    margin-left: 250px !important;
}

.Toastify__toast-body.texto_toastify{
    color: "#121212" !important;
}

