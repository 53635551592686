.checkbox{
    padding-top: 12px;
}
.segment_size{
    //width: calc(100% - 500px) !important;
    margin-left: 250px !important;
}

.Toastify__toast-body.texto_toastify{
    color: "#121212" !important;
}


.segment_search .input_search{
    border: 1px solid !important;
    border-radius: 7px !important;
    
        &.input.input_search{
            border: 1px solid !important;
            border-radius: "7px";
        }
}

.segment_border_primary{
    border-top: 4px solid;
    border-color: rgb(64, 117, 142);
}
.segment_border_secondary{
    border-top: 4px solid;
    border-color: rgb(225 110 64 / 26%);
}
.botonAcciones{
    margin-top: 35px !important;
}

.ui .disabled.input {
    opacity: 1.5 !important;
}
.ui.form .field.disabled :disabled {
    color: black !important;
}

.dropzone{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
}

i.inverted.bordered.icon.color_search, i.inverted.circular.icon.color_search {
    background-color: rgb(64, 117, 142) !important;
    color: #fff!important;
}