.avatar{
    color: #fafafa;
    background-color:rgb(64, 117, 142) !important;
    flex: 0 0 auto;
    margin-right: 16px;
    width: 30px;
    height: 30px;
    display: flex;
    overflow: hidden;
    position: relative;
    font-size: 1.25rem;
    align-items: center;
    flex-shrink: 0;
    font-family: Open Sans, sans-serif;
    line-height: 1;
    user-select: none;
    border-radius: 50%;
    justify-content: center;

} 
h4.cardContent {
   
    display: flex !important;
    border-radius: 12px !important;
    align-items: center !important;
   /*  padding: 16px;
    align-items: center;
    overflow: hidden;
     */
}

.nameCard{
    flex: 1 1 auto;
    font-size: 16px;
    color:rgb(64, 117, 142) !important;
}

.cardPadre{
    padding: 16px !important;
    background-color: rgb(247, 247, 247) !important;
}


