.top-menu-admin{
    background-color: rgb(64 117 142) !important;
    /* background-color: #212529 !important; */
   
    &__izq{
        &__logo{
            width: 227px !important;
           /*  width: 48px !important; */
            
        }
        width: 250px;
        
    }
    .item{
        color: white !important;
    }
    i.icon{
        color:  rgb(225 110 64)!important;

        margin: 0 !important;
    }
}



.navbar {
    display: flex;
    height: var(--top-nav-height);
    justify-content: space-between;
    align-items: center;
    background: var(--primary-color);
    border-top-left-radius: var(--margin);
    border-top-right-radius: var(--margin);
    position: fixed;
    top: var(--margin);
    right: var(--margin);
    left: var(--margin);
    transition: all 0.3s ease;
  }
  
  .navbar .brand {
    display: flex;
    align-items: center;
  }
  .navbar .brand .logo a {
    color: #ffffff;
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 600;
  }

  .navbar .left  {
    color: #ffffff;
    i {
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 26px;
        font-size: 1.2rem;
        
        border-radius: 50%;
        border: 1px solid #ffffff;
        margin: 0 15px;
        cursor: pointer;
    }
  }
  .navbar .left i:hover {
    color: var(--primary-color);
    background: #ffffff;
  }

  .notification-icon {
    position: relative;
    margin-right: 10px;
  }
  .notification-icon span {
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 4px 8px;
    font-size: 12px;
  }
  .top-menu-admin__color_brown.active {
    /* Agrega estilos específicos cuando hay notificaciones activas */
    color: red; /* Por ejemplo, cambiar el color a rojo */
    font-weight: bold; /* Puedes hacer que el icono sea más llamativo */
  }