.admin-layout{
    width: 100%;
    display: grid;
    grid: 55px / 1fr;
    grid-template-areas:
    "menu"
    "content";
    min-height: 100vh;

    &__menu{
        grid-area: menu;
    }
    &__main-content{
        grid-area:content;
    }
}

.top-wrapper {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.06), 0 8px 16px 0 rgba(0, 0, 0, 0.2),
      0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }